<template>
     <div class="container_reservas container mt-5" style="">
         <div class="searchPropiedad_container">
            <button @click="handleBack" class="btn btn-primary">{{ ver_propiedades}}</button>
            <div class="searchPropiedad_content">
                <label for="searchPropiedad" class="mb-3"></label>
                <input @change="handleInputSearch" v-model="searchItem" class="input" name="searchPropiedad" :placeholder="nombre_del_huesped">
                
            </div>
            <div class="fechas_container">
                <label for="fecha1"><b>{{desde}}</b></label> 
                <input type="date" name="fecha1" @change="handleDateFilter" v-model="fecha1">
                <label for="fecha2"><b>{{hasta}}</b></label>
                <input type="date" name="fecha2" @change="handleDateFilter" v-model="fecha2">

            </div>
          </div>

       
                
            <b-table v-if="reservas.length !== 0" class="animate__animated animate__fadeInUp mt-5" sticky-header striped outlined bordered head-variant="dark" hover :items="items_table" :fields="fields">
            </b-table>
      
        <h4 v-else>Cargando...</h4>
     </div>
</template>
<script >
    export default {
        data(){
            return{ 
                ver_propiedades:"...",
                nombre_del_huesped:"",
                desde:"",
                hasta:"",
                ID_RESERVA_text:"",
                FECHA_ENTRADA_text:"",
                FECHA_SALIDA_text:"",
                HUESPED_text:"",
                PRECIO_RESERVA_text:"",
                LIMPIEZA_text:"",
                COMISION_PORTAL_text:"",
                ESTADO_text:"",
                PENDIENTE_text:"",
                ACTIVA_text:"",
                FINALIZADA_text:"",
                fecha1:null,
                fecha2:null,
                reservas:[],
                items_table:[],
                 fields: [
                { key: 'ID_RESERVA', sortable: true, label:"" },
                { key: 'FECHA_ENTRADA', label:""},
                { key: 'FECHA_SALIDA', label: "" },
                { key: 'HUESPED' , label: "" },
                { key: 'PRECIO_RESERVA', class:'profit', sortable:true, label: "" },
                { key: "SUPLEMENTOLIMPIEZA",label:"LIMPIEZA", class: 'profit', sortable: true},
                { key: "COMISION_PORTAL", class:'comision', sortable: true, label: "" },
                { key: 'ESTADO', sortable:true, label: "" }
            ] ,
            searchItem:''
            }
        },
       async mounted() {
           
            let propiedad = JSON.parse(localStorage.getItem('propiedadActiva')) 
            let {ID_PROPIEDAD:id} = propiedad
            
            let response  = await fetch(`${this.$store.state.urlClients}reservas/${id}`)
            let reservas  = await response.json()
            
            
            if(reservas.data.length === 0){
                
                this.reservas = [{
                ID_RESERVA: 'NO RESULT',
                FECHA_ENTRADA: 'NO RESULT',
                FECHA_SALIDA: 'NO RESULT',
                HUESPED: 'NO RESULT',
                PRECIO_RESERVA: 'NO RESULT',
                SUPLEMENTOLIMPIEZA: 'NO RESULT',
                COMISION_PORTAL: 'NO RESULT',
                ESTADO: 'NO RESULT'
            }]
            this.items_table.push(this.reservas[0])
            }else{
                this.reservas = reservas.data
            } 
            this.llenarCampos()
             this.$store.watch(
            (state) => [state.recarga].join(),
            async () => {
                // this.showItemsNumber();
                // this.refreshButton();

                this.llenarCampos()
                
                //console.log(this.textos)
            }
        );
            this.llenarTablz(this.reservas)
            
        },
        methods:{

           async llenarCampos(){
                let textos = JSON.parse(localStorage.getItem("text"))

                this.ver_propiedades= textos.cliente_reservas_button_verpropiedades
                this.nombre_del_huesped= textos.cliente_reservas_input_placeholder
                this.desde= textos.cliente_reservas_label_desde
                this.hasta= textos.cliente_reservas_label_hasta
                this.ID_RESERVA_text= textos.cliente_table_reservas_idreserva
                this.fields[0].label = this.ID_RESERVA_text.split("_").join(" ")
                this.FECHA_ENTRADA_text= textos.cliente_table_reservas_fecha_entrada
                this.fields[1].label = this.FECHA_ENTRADA_text.split("_").join(" ")
                this.FECHA_SALIDA_text= textos.cliente_table_reservas_fecha_salida
                this.fields[2].label = this.FECHA_SALIDA_text.split("_").join(" ")
                this.HUESPED_text= textos.cliente_table_reservas_huesped
                this.fields[3].label = this.HUESPED_text
                this.PRECIO_RESERVA_text= textos.cliente_table_reservas_precio_reserva
                this.fields[4].label = this.PRECIO_RESERVA_text.split("_").join(" ")
                this.LIMPIEZA_text= textos.cliente_table_reservas_limpieza
                this.fields[5].label = this.LIMPIEZA_text
                this.COMISION_PORTAL_text= textos.cliente_table_reservas_comision_portal
                this.fields[6].label = this.COMISION_PORTAL_text.split("_").join(" ")
                this.ESTADO_text= textos.cliente_table_reservas_estado
                this.fields[7].label = this.ESTADO_text
                this.PENDIENTE_text= textos.cliente_table_estado_pendiente
                this.ACTIVA_text= textos.cliente_table_estado_activa
                this.FINALIZADA_text= textos.cliente_table_estado_finalizada
                
            this.resrevas= []
            let propiedad = JSON.parse(localStorage.getItem('propiedadActiva'))
            let { ID_PROPIEDAD: id } = propiedad

            let response = await fetch(`${this.$store.state.urlClients}reservas/${id}`)
            let reservas = await response.json()


            if (reservas.data.length === 0) {

                this.reservas = [{
                    ID_RESERVA: 'NO RESULT',
                    FECHA_ENTRADA: 'NO RESULT',
                    FECHA_SALIDA: 'NO RESULT',
                    HUESPED: 'NO RESULT',
                    PRECIO_RESERVA: 'NO RESULT',
                    SUPLEMENTOLIMPIEZA: 'NO RESULT',
                    COMISION_PORTAL: 'NO RESULT',
                    ESTADO: 'NO RESULT'
                }]
                this.items_table.push(this.reservas[0])
            } else {
                this.reservas = reservas.data
            }
                this.reservas.map(reserva=>{
                if (reserva.ESTADO.trim() === "ACTIVA") {
                    reserva.ESTADO = this.ACTIVA_text
                }
                if (reserva.ESTADO.trim() === "PENDIENTE") {
                    reserva.ESTADO = this.PENDIENTE_text
                }
                if (reserva.ESTADO.trim() === "FINALIZADA" || reserva.ESTADO.trim() === "CANCELADA") {
                    reserva.ESTADO = this.FINALIZADA_text
                }
                 if (reserva.ESTADO.trim() === "MANTENIMIENTO") {
                    reserva.ESTADO = "MAINTENANCE"
                }
                })
                
                this.llenarTablz(this.reservas)
                
            },
            llenarTablz(reservas) {
                    this.items_table = [];
                    reservas.map(reserva => {
                    let fechaEntrada = `${new Date(reserva.FECHA_ENTRADA).getDate()}/${new Date(reserva.FECHA_ENTRADA).getMonth() + 1}/${new Date(reserva.FECHA_ENTRADA).getFullYear()}`

                    let fechaSalida = `${new Date(reserva.FECHA_SALIDA).getDate()}/${new Date(reserva.FECHA_SALIDA).getMonth() + 1}/${new Date(reserva.FECHA_SALIDA).getFullYear()}`

                    if(reserva.NOMBRE == null){
                        console.log(reserva.NOMBRE)
                        reserva.NOMBRE = ""
                    }
                    if (reserva.APELLIDO == null) {
                        reserva.APELLIDO = ""
                    }
                    
                    
                    let reservaFiltered = {
                        ID_RESERVA: reserva.ID_RESERVA,
                        FECHA_ENTRADA: fechaEntrada,
                        FECHA_SALIDA: fechaSalida,
                        HUESPED: `${reserva.NOMBRE?.trim()} ${reserva.APELLIDO.trim()}`  ,
                        PRECIO_RESERVA: '\u20AC ' + reserva.PRECIO,
                        SUPLEMENTOLIMPIEZA: '\u20AC ' + (reserva?.GASTOLIMPIEZAACOBRAR_PROPIETARIO === null ? 0 : reserva?.GASTOLIMPIEZAACOBRAR_PROPIETARIO),
                        COMISION_PORTAL: '\u20AC ' + (reserva.COMISION === null ?  0 : reserva.COMISION),
                        ESTADO: reserva.ESTADO
                    }
                    this.items_table.push(reservaFiltered)
                })
                
            },
             handleInputSearch() {
                setTimeout(() => {
                    
                    this.items_table = []
                    let reservasCopy = [...this.reservas]
                    
                    reservasCopy.map(item => {  
                        let fechaEntrada = `${new Date(item.FECHA_ENTRADA).getDate()}/${new Date(item.FECHA_ENTRADA).getMonth() + 1}/${new Date(item.FECHA_ENTRADA).getFullYear()}`
   
                        let fechaSalida = `${new Date(item.FECHA_SALIDA).getDate()}/${new Date(item.FECHA_SALIDA).getMonth() + 1}/${new Date(item.FECHA_SALIDA).getFullYear()}`
                        let huespedName = `${item.NOMBRE.trim()} ${item.APELLIDO.trim()}`
                       
                       
                       if (huespedName.toUpperCase().indexOf(this.searchItem.toUpperCase()) !== -1) {
                           let reservaFiltered = {
                            ID_RESERVA: item.ID_RESERVA,
                            FECHA_ENTRADA: fechaEntrada,
                            FECHA_SALIDA: fechaSalida,
                            HUESPED: huespedName,
                            PRECIO_RESERVA: '\u20AC ' + item.PRECIO,
                            SUPLEMENTOLIMPIEZA: '\u20AC ' + (item.SUPLIMPIEZA === null ? 0 : item.SUPLIMPIEZA),
                            COMISION_PORTAL: '\u20AC ' + (item.COMISION ===null ? 0: item.COMISION)
                       }
                           this.items_table.push(reservaFiltered)
                           
                           
                       }
   
                   })
                   if (this.items_table.length === 0) {
                       this.items_table = [{
                           ID_PROPIEDAD: '0000',
                           NOMBRE_PROPIEDAD: 'NO RESULT',
                           DIRECCION: 'NO RESULT',
                           TELEFONO: 'NO RESULT',
                           PROVINCIA: 'NO RESULT'
                       }]
               }
                }, 100);


        },

        handleDateFilter(){
            let fechaDesde = new Date(this.fecha1)
            let fechaHasta = new Date(this.fecha2)
            let reservasFiltradas = []
            this.reservas.map(reserva =>{
                let fechaReservaSeconds = new Date(reserva.FECHA_ENTRADA).getTime()
                if(fechaReservaSeconds>= fechaDesde && fechaReservaSeconds <= fechaHasta){

                     let fechaEntrada = `${new Date(reserva.FECHA_ENTRADA).getDate()}/${new Date(reserva.FECHA_ENTRADA).getMonth() + 1}/${new Date(reserva.FECHA_ENTRADA).getFullYear()}`

                    let fechaSalida = `${new Date(reserva.FECHA_SALIDA).getDate()}/${new Date(reserva.FECHA_SALIDA).getMonth() + 1}/${new Date(reserva.FECHA_SALIDA).getFullYear()}`

                    let formatReserva = {
                        ID_RESERVA: reserva.ID_RESERVA,
                        FECHA_ENTRADA: fechaEntrada,
                        FECHA_SALIDA: fechaSalida,
                        HUESPED: `${reserva.NOMBRE.trim()} ${reserva.APELLIDO.trim()}`,
                        PRECIO_RESERVA: '\u20AC ' + reserva.PRECIO,
                        SUPLEMENTOLIMPIEZA: '\u20AC ' + (reserva?.GASTOLIMPIEZAACOBRAR_PROPIETARIO === null ? 0 : reserva?.GASTOLIMPIEZAACOBRAR_PROPIETARIO),
                        COMISION_PORTAL: '\u20AC ' + (reserva.COMISION === null ? 0 : reserva.COMISION),
                        ESTADO: reserva.ESTADO
                    }
                    reservasFiltradas.push(formatReserva)
                }

                
            })

            this.items_table = []
            this.items_table = reservasFiltradas
            console.log(this.items_table)
        },

        handleBack() {
            localStorage.setItem("propiedadActiva", null)
            window.history.back()
        }
        },
         watch: {
        
    }
}
</script>
<style>
.searchPropiedad_container{
    display: flex;
    justify-content: space-between;
}
.input{
    outline: none;
    display: inline-block;
    padding:8px 8px ;
    height: 28px;
    margin-left: 8px;
}
.fechas_container{
    display: flex;
    align-items: baseline;
    gap: 6px;
 }
.profit{
    color: green;
}
.comision{
    color: red;
}
 .container_reservas{
   max-width: 1128px !important;
 }
@media (max-width: 1095px) {
 .container_reservas{
   max-width: 840px !important;
 }
}
@media (max-width: 1075px) {
 .container_reservas{
   max-width: 732px !important;
 }
}
@media (max-width: 1075px) {
 .container_reservas{
   max-width: 758px !important;
 }
}
@media (max-width: 992px) {
 .container_reservas{
   max-width: 726px !important;
 }
}
@media (max-width: 955px) {
 .container_reservas{
   max-width:906px !important;
 }
}



@media (max-width: 750px) {
 .fechas_container{
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;
 }
 .fechas_container label{
    margin: 0;
 }
 .searchPropiedad_container{
    gap: 12px !important; 
    flex-direction: column;
    align-items: baseline;
 }
 input[name="searchPropiedad"]{
    margin-left: 0;
 }
}

</style>